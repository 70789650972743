import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Link,
  Grid2,
  Paper,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Logo from "../images/Logo For Website 02.png";
import Logo1 from "../images/Logo For Website 01.png";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { loginWithGoogle } from "../redux/actions/loginActions";
import { useDispatch } from "react-redux";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isGoogleSignUp, setIsGoogleSignUp] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    isGoogleSignUp: false,
    pricePlan: "Free",
    perDayLimit: 5,
  });
  const [showPassword, setShowPassword] = useState(false);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleScreenClick = () => {
    navigate("/");
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Here you can submit the formData to your API or log it
    const loadingToastId = toast.loading("Signing up...");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/Auth/signup`,
        formData
      );

      if (response.status === 200) {
        toast.update(loadingToastId, {
          render: "Sign up successful! Please login.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });

        // Redirect to login page after success
        navigate("/");
      }
    } catch (error) {
      toast.update(loadingToastId, {
        render: error.response?.data?.message || "Sign up failed. Try again.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const handleGoogleSuccess = async (response) => {
    const userObject = jwtDecode(response.credential);
    console.log("Google User:", userObject);

    const loadingToastId = toast.loading("Sign up with Google...");
    setIsGoogleSignUp(true);

    try {
      const googleUser = {
        firstName: userObject.given_name,
        lastName: userObject.family_name,
        email: userObject.email,
        password: "",
        pricePlan: "Free",
        perDayLimit: 5,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/Auth/signup`,
        { ...googleUser, isGoogleSignUp: true }
      );

      if (response.status === 200) {
        dispatch(loginWithGoogle(response.data));
      } else {
        dispatch(loginWithGoogle(response.data));
      }
      toast.update(loadingToastId, {
        render: "Logged in successfully with Google!",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
      navigate("/");
    } catch (error) {
      toast.update(loadingToastId, {
        render: "Login failed. Please try again.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
      console.error("Google login failed:", error);
    }
  };

  const handleLoginRedirect = () => {
    navigate("/login");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid2 container style={{ minHeight: "100vh" }}>
      <Grid2
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f5f5f5",
          width: { xs: "100%", md: "50%" },
          minHeight: { xs: "100vh", md: "auto" },
          padding: { xs: "20px", md: 0 },
        }}
      >
        <Paper
          elevation={0}
          style={{ padding: "20px", borderRadius: "8px", width: "100%" }}
        >
          <Box textAlign="center" mb={2}>
            <img
              src={Logo1}
              alt="Logo"
              style={{ width: "200px", maxWidth: "100%" }}
            />
          </Box>
          <Typography variant="h5" align="center" gutterBottom>
            Sign Up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "15px 12px",
                },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "15px 12px",
                },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "15px 12px",
                },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "15px 12px",
                },
              }}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: "20px" }}
            >
              Sign Up
            </Button>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              my={2}
            >
              <hr
                style={{
                  flexGrow: 1,
                  border: "1px solid #ccc",
                  marginRight: "10px",
                }}
              />
              <Typography variant="body2" style={{ margin: "0 10px" }}>
                OR
              </Typography>
              <hr
                style={{
                  flexGrow: 1,
                  border: "1px solid #ccc",
                  marginLeft: "10px",
                }}
              />
            </Box>
            <Grid2
              container
              justifyContent="center"
              style={{ marginTop: "20px" }}
            >
              <Grid2>
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={() => console.log("Google sign-up failed")}
                />
              </Grid2>
            </Grid2>
            <Box textAlign="center" mt={2}>
              <Typography variant="body2">
                Already have an account?{" "}
                <Link
                  href="#"
                  variant="body2"
                  color="primary"
                  onClick={handleLoginRedirect}
                >
                  Sign In
                </Link>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid2>
      <Grid2
        item
        md={6}
        sx={{
          display: { xs: "none", md: "flex" },
          cursor: "pointer",
          backgroundColor: "#000000",
          justifyContent: "center",
          alignItems: "center",
          width: "50%",
        }}
        onClick={handleScreenClick}
      >
        <Box textAlign="center">
          <img
            src={Logo}
            alt="Logo"
            style={{ width: "300px", maxWidth: "100%" }}
          />
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default SignUp;

import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Grid,
  ListItemIcon,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Play,
  SquareTerminal,
  //Users,
  //GraduationCap,
  Sparkle,
  Puzzle,
  Trophy,
  BadgePercent,
  StickyNote,
} from "lucide-react";

const Sidebar = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:960px)");
  const headerHeight = "64px";

  const navigationItems = [
    {
      name: "Competition",
      route: "/competition",
      icon: <Play />,
      active: true,
      showMobile: true,
    },
    { type: "label", name: "Discover" },
    { name: "Home", route: "/", icon: <SquareTerminal />, showMobile: true },
    //{ name: "Duelists", route: "/duelist", icon: <Users />, showMobile: true },
    { name: "AI Teacher", route: "/aiteacherchat", icon: <Sparkle />, showMobile: true },
    // {
    //   name: "Learn",
    //   route: "/learn",
    //   icon: <GraduationCap />,
    //   showMobile: true,
    // },
    { name: "Puzzles", route: "/puzzles", icon: <Puzzle />, showMobile: true },
    { type: "label", name: "Tournaments" },
    { name: "IMO", route: "/imoroadmap", icon: <Trophy /> },
    { name: "Kangaroo", route: "/kangaroo", icon: <Trophy /> },
    { name: "Pricing", route: "/pricing", icon: <BadgePercent /> },
    { type: "label", name: "Tools" },
    {
      name: "Picture to Latex",
      route: "/pic-to-latex",
      icon: (
        <img
          src="/latex.png"
          alt="Pic to Latex"
          style={{
            width: "24px",
            height: "24px",
            objectFit: "contain",
          }}
        />
      ),
    },
    { name: "Feedback", route: "/feedback", icon: <StickyNote />, showMobile: true },
  ];

  const handleNavigation = (route) => {
    navigate(route);
  };

  const mobileItems = navigationItems.filter(item => item.showMobile);

  return (
    <>
      {/* Spacers */}
      {isMobile ? (
        // Mobile bottom navigation spacer
        <div style={{ height: "72px" }} />
      ) : (
        // Desktop left sidebar spacer
        <div style={{ width: "264px", flexShrink: 0 }} /> // Increased width to account for spacing
      )}

      <Grid
        item
        sx={{
          width: { xs: "100%", md: "240px" },
          height: { xs: "auto", md: `calc(100vh - ${headerHeight})` },
          position: "fixed",
          ...(isMobile ? {
            // Mobile styles
            bottom: 0,
            left: 0,
            borderTop: "1px solid #eaeaea",
            padding: "12px 16px",
            backgroundColor: "#fff",
            zIndex: 1000,
          } : {
            // Desktop styles - add left spacing
            top: headerHeight,
            left: "24px", // Add left spacing here
            borderRight: "1px solid #eaeaea",
            padding: "16px",
            backgroundColor: "#fff",
            zIndex: 998,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "2px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }),
        }}
      >
        <List
          sx={{
            padding: 0,
            display: "flex",
            flexDirection: { xs: "row", md: "column" },
            alignItems: { xs: "center", md: "stretch" },
            justifyContent: { xs: "space-around", md: "flex-start" },
            gap: { xs: 1, md: 0 },
            flex: 1,
            "& .MuiListItem-root": {
              padding: { xs: "8px", md: "8px 12px" },
              borderRadius: "8px",
              minWidth: { xs: "auto", md: "100%" },
              width: { xs: "auto", md: "100%" },
              transition: "all 0.2s ease",
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
            },
            "& .MuiListItem-root.active": {
              backgroundColor: "#50C878",
              color: "#fff",
              "& .MuiListItemIcon-root": {
                color: "#fff",
              },
              "& .MuiListItemText-primary": {
                color: "#fff",
              },
              "&:hover": {
                backgroundColor: "#45b86b",
              },
            },
            "& .MuiListItem-root.play-button": {
              backgroundColor: "#50C878",
              color: "#fff",
              marginBottom: { md: "8px" },
              "& .MuiListItemIcon-root": {
                color: "#fff",
              },
              "& .MuiListItemText-primary": {
                color: "#fff",
                display: { xs: "none", md: "block" },
              },
            },
            "& .MuiListItemIcon-root": {
              minWidth: { xs: "24px", md: "32px" },
              color: "#666",
              justifyContent: "center",
            },
            "& .MuiListItemText-primary": {
              fontSize: "14px",
              fontWeight: 500,
              color: "#333",
              display: { xs: "none", md: "block" },
            },
            "& .MuiListSubheader-root": {
              display: { xs: "none", md: "block" },
              padding: "16px 12px 4px 12px",
              fontSize: "12px",
              fontWeight: 600,
              color: "#888",
              backgroundColor: "transparent",
              lineHeight: "1.5",
              textTransform: "uppercase",
              letterSpacing: "0.5px",
            },
          }}
        >
          {isMobile ? (
            // Mobile navigation items
            mobileItems.map((item, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleNavigation(item.route)}
                className={item.name === "Play" ? "play-button" : item.active ? "active" : ""}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            ))
          ) : (
            // Desktop navigation items with footer
            <>
              {navigationItems.map((item, index) => {
                if (item.type === "label") {
                  return (
                    <ListSubheader key={index} disableSticky>
                      {item.name}
                    </ListSubheader>
                  );
                }

                const isLastItem = index === navigationItems.length - 1;

                return (
                  <React.Fragment key={index}>
                    <ListItem
                      button
                      onClick={() => handleNavigation(item.route)}
                      className={item.name === "Play" ? "play-button" : item.active ? "active" : ""}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.name} />
                    </ListItem>
                    {isLastItem && (
                      <div style={{
                        padding: "4px 12px",
                        fontSize: "13px",
                        color: "#666",
                        marginTop: "auto"
                      }}>
                        <span 
                          onClick={() => handleNavigation('/about')} 
                          style={{ cursor: "pointer" }}
                        >
                          About
                        </span>
                        {" • "}
                        <span 
                          onClick={() => handleNavigation('/privacy')} 
                          style={{ cursor: "pointer" }}
                        >
                          Privacy Policy
                        </span>
                        {" • "}
                        <span 
                          onClick={() => handleNavigation('/termscondition')} 
                          style={{ cursor: "pointer" }}
                        >
                          T&C
                        </span>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </>
          )}
        </List>
      </Grid>
    </>
  );
};

export default Sidebar;
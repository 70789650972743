import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Typography,
  Button,
  TextField,
  Box,
  CssBaseline,
  IconButton,
  Select,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { fetchUsers } from "../redux/reducers/usersSlice";

const DueList = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.getUsers.users);
  const [visibleProfiles, setVisibleProfiles] = useState(6); // Initially show 6 profiles
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [open, setOpen] = useState(false); // For the dialog
  const [role, setRole] = useState(""); // For the input value

  const { user } = useSelector((state) => state);
  let currentUserRole = null;

  if (user.isLoggedIn) {
    if (user.loginMethod === "custom" && user.customUser.role.length > 0) {
      currentUserRole = user.customUser.role[0];
    } else if (
      user.loginMethod === "google" &&
      user.googleUser.role.length > 0
    ) {
      currentUserRole = user.googleUser.role[0];
    }
  }

  const showIconButton =
    currentUserRole === "Moderator" || currentUserRole === "Admin";
  const isAdmin = currentUserRole === "Admin";
  const isModerator = currentUserRole === "Moderator";

  const handleMenuClick = (event, profile) => {
    setSelectedUser(null);
    setAnchorEl(event.currentTarget);
    setSelectedUser(profile);
  };

  // Handle closing the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true); // Open the dialog
    handleMenuClose(); // Close the 3-dot menu when opening the dialog
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  const handleRoleSubmit = async () => {
    console.log("submitting role", selectedUser.userId);

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/Users/ChangeUserRole/${selectedUser.userId}`,
        {
          role: role,
        }
      );

      console.log(response);

      if (response.status === 200) {
        await dispatch(fetchUsers());
        handleClose();
      } else {
        alert("Failed to update the user role");
      }
    } catch (error) {
      alert("An error occurred while updating the user role");
    }
  };

  const handleLoadMore = () => {
    setVisibleProfiles((prevVisible) => prevVisible + 6); // Load 6 more profiles on click
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  // Filter profiles based on the search query
  const filteredProfiles = users.filter(
    (profile) =>
      profile.firstName.toLowerCase().includes(searchQuery) ||
      profile.lastName.toLowerCase().includes(searchQuery) ||
      profile.email.toLowerCase().includes(searchQuery)
  );

  return (
    <>
      <CssBaseline />
      <Grid container>
        <Sidebar />
        <Grid 
          item 
          sx={{ 
            marginLeft: { xs: 0, md: '240px' },
            width: { xs: '100%', md: 'calc(100% - 240px)',xl: "calc(100% - 450px)" }
          }}
        >
          <Box sx={{ padding: "20px", maxWidth: "1200px", margin: "0 auto" }}>
            <div style={{ padding: "20px" }}>
              <Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Due Lists
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Your mentors and friends.
                </Typography>
              </Box>
              {/* Search Bar */}
              <TextField
                variant="outlined"
                placeholder="Search..."
                fullWidth
                style={{
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    padding: "8px 14px",
                  },
                }}
                onChange={handleSearch}
              />

              <Grid container spacing={3}>
                {users.slice(0, visibleProfiles).map((profile, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Card
                      sx={{
                        height: 250,
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                      }}
                    >
                      {showIconButton && (
                        <IconButton
                          size="small"
                          sx={{
                            position: "absolute",
                            top: 5,
                            right: 5,
                          }}
                          onClick={(event) => handleMenuClick(event, profile)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )}

                      {/* Rectangular Image at the Top */}
                      <Box
                        component="img"
                        src={profile.image || "https://via.placeholder.com/150"}
                        alt={profile.name}
                        sx={{
                          width: "100%",
                          height: 200,
                          objectFit: "cover",
                        }}
                      />

                      <CardContent
                        sx={{
                          maxWidth: "100%",
                          textAlign: "center",
                          flexGrow: 1,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          padding: "0px",
                          margin: "auto",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "0.8rem",
                          }}
                          gutterBottom
                        >
                          {profile.firstName} {profile.lastName}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ fontSize: ".6rem" }}
                          color="textSecondary"
                        >
                          {profile.role && profile.role.length > 0
                            ? profile.role[0]
                            : "User"}
                        </Typography>
                      </CardContent>

                      {/* Menu for actions */}
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedUser === profile}
                        onClose={handleMenuClose}
                      >
                        {isModerator && (
                          <MenuItem
                            onClick={() => {
                              /* Add ban user functionality */
                            }}
                          >
                            Ban User
                          </MenuItem>
                        )}
                        {isAdmin && (
                          <>
                            <MenuItem
                              onClick={() => {
                                /* Add ban user functionality */
                              }}
                            >
                              Ban User
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleClickOpen();
                              }}
                            >
                              Assign Role
                            </MenuItem>
                          </>
                        )}
                      </Menu>
                      {/* Dialog for assigning role */}
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        fullWidth
                        maxWidth="sm"
                        sx={{
                          "& .MuiDialog-paper": {
                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                            boxShadow: "none",
                          },
                          "& .MuiBackdrop-root": {
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                          },
                        }}
                        aria-hidden={open ? "false" : "true"}
                      >
                        <DialogTitle>Assign Role</DialogTitle>
                        <DialogContent>
                          <FormControl fullWidth>
                            <InputLabel id="role-select-label">Role</InputLabel>
                            <Select
                              labelId="role-select-label"
                              id="role-select"
                              value={role}
                              label="Role"
                              onChange={(e) => setRole(e.target.value)}
                            >
                              <MenuItem value="Admin">Admin</MenuItem>
                              <MenuItem value="Moderator">Moderator</MenuItem>
                              <MenuItem value="User">User</MenuItem>
                            </Select>
                          </FormControl>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose} color="inherit">
                            Cancel
                          </Button>
                          <Button
                            onClick={() => handleRoleSubmit()}
                            color="primary"
                            variant="contained"
                          >
                            Submit
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              {/* Load More Button */}
              {visibleProfiles < filteredProfiles.length && (
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <Button variant="contained" onClick={handleLoadMore}>
                    Load More
                  </Button>
                </div>
              )}
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default DueList;

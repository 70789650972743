import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  CssBaseline,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Send } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "./Sidebar";

const CompetitionSubmission = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [showThankYouDialog, setShowThankYouDialog] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Replace 'YOUR_FORMSPREE_ID' with your actual Formspree form ID
      const response = await fetch("https://formspree.io/f/mzzbvjlq", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
      });

      if (response.ok) {
        setShowThankYouDialog(true);
        setFormData({
          name: "",
          email: "",
          phone: "",
          
        });
      } else {
        throw new Error("Failed to submit");
      }
    } catch (error) {
      console.error("Error submitting entry:", error);
      toast.error("Something went wrong, please try again.", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleCloseDialog = () => {
    setShowThankYouDialog(false);
    navigate("/");
  };

  return (
    <>
      <CssBaseline />
      <Grid container>
        <Sidebar />
        <Grid
          item
          sx={{ 
            marginLeft: { xs: 0, md: '240px' },
            width: { xs: '100%', md: 'calc(100% - 240px)', xl: "calc(100% - 450px)" }
          }}
        >
          <Box sx={{ padding: "40px" }}>
            <Box>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Math Competition Submission
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Submit your solution for the mathematics competition. Please fill in all required information.
              </Typography>
            </Box>

            <Paper
              elevation={0}
              sx={{
                mt: 4,
                p: 4,
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
              }}
            >
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  name="name"
                  label="Full Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  sx={{ mb: 3 }}
                />

                <TextField
                  fullWidth
                  name="email"
                  type="email"
                  label="Email Address"
                  value={formData.email}
                  onChange={handleInputChange}
                  //required
                  sx={{ mb: 3 }}
                />

                <TextField
                  fullWidth
                  name="phone"
                  label="Phone Number"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Optional"
                  sx={{ mb: 3 }}
                />

                <TextField
                  fullWidth
                  name="solution"
                  label="Your Solution"
                  multiline
                  rows={6}
                  required
                  placeholder="Type or paste your solution here..."
                  sx={{ mb: 4 }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#0F172A",
                    padding: "8px 24px",
                    "&:hover": {
                      backgroundColor: "#1E293B",
                    },
                  }}
                  startIcon={<Send size={20} />}
                >
                  Submit Solution
                </Button>
              </form>
            </Paper>
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={showThankYouDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            padding: "20px",
          },
        }}
      >
        <DialogTitle>
          Submission Received!
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="text.secondary">
            Thank you for participating in the mathematics competition. Your solution has been successfully submitted. We will contact you through your provided contact information if needed.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            sx={{
              backgroundColor: "#0F172A",
              color: "white",
              "&:hover": {
                backgroundColor: "#1E293B",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CompetitionSubmission;
import React from "react";
import { Grid, Typography, Box, CssBaseline } from "@mui/material";
import { Circle } from "lucide-react";
import Sidebar from "./Sidebar";

const steps = [
  {
    title: "Before applying to IMO what you have to have or be",
    items: [
      "Be under the age of 20 on the day of the IMO closing ceremony",
      "Not be enrolled at a university or equivalent institution",
      "Be selected by your country's mathematical olympiad organization",
    ],
  },
  {
    title: "First step",
    content: "Participate in your national mathematical olympiad program",
  },
  {
    title: "Second step",
    content:
      "Excel in national competitions and selection tests organized by your country's mathematical society",
  },
  {
    title: "Third step",
    content:
      "Attend intensive training camps if selected for the national team pool",
  },
  {
    title: "Fourth step",
    content:
      "Master key topics: Number Theory, Algebra, Geometry, Combinatorics",
  },
  {
    title: "Fifth step",
    content:
      "Participate in team selection tests (usually 4-5 tests over several months)",
  },
  {
    title: "Final selection",
    content:
      "Top 6 students will be selected to represent their country at IMO",
  },
  {
    title: "Competition format",
    content:
      "Two days of competition, three problems each day, 4.5 hours each day. Each problem is worth 7 points, for a maximum of 42 points",
  },
];

const IMORoadmap = () => {
  return (
    <>
      <CssBaseline />
      <Grid container>
        <Sidebar />
        <Grid 
          item 
          sx={{ 
            marginLeft: { xs: 0, md: '240px' },
            width: { xs: '100%', md: 'calc(100% - 240px)',xl: "calc(100% - 450px)" }
          }}
        >
          <Box sx={{ padding: "20px" }}>
            <div style={{ padding: "20px" }}>
              <Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  IMO Math Olympiad Roadmap
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Your path to the International Mathematical Olympiad.
                </Typography>
              </Box>

              <Box sx={{ mt: 4, position: "relative" }}>
                {/* Vertical Timeline Line */}
                <Box
                  sx={{
                    position: "absolute",
                    left: "7px",
                    top: "24px",
                    bottom: "0",
                    width: "2px",
                    backgroundColor: "#e0e0e0",
                    zIndex: 0,
                  }}
                />

                {steps.map((step, index) => (
                  <Box
                    key={index}
                    sx={{
                      mb: 3,
                      position: "relative",
                      pl: "30px" // Space for timeline
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        top: "2px",
                        backgroundColor: "white",
                        zIndex: 1,
                      }}
                    >
                      <Circle size={14} />
                    </Box>

                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ mb: 1 }}
                    >
                      {step.title}
                    </Typography>

                    {step.items ? (
                      <Box sx={{ ml: 2 }}>
                        {step.items.map((item, i) => (
                          <Typography
                            key={i}
                            variant="body2"
                            color="text.secondary"
                            sx={{ mb: 1 }}
                          >
                            • {item}
                          </Typography>
                        ))}
                      </Box>
                    ) : (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ ml: 2 }}
                      >
                        {step.content}
                      </Typography>
                    )}
                  </Box>
                ))}

                <Box sx={{ mt: 4, ml: "30px" }}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{ mb: 1 }}
                  >
                    Awards
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ ml: 2 }}>
                    In July, after the competition, medals are awarded:
                    <br />• Gold (top ~8%)
                    <br />• Silver (next ~16%)
                    <br />• Bronze (next ~24%)
                    <br />Keep practicing and good luck! 🏅
                  </Typography>
                </Box>
              </Box>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default IMORoadmap;
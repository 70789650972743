// postSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk to fetch post by ID using Axios
export const fetchPostById = createAsyncThunk(
  "post/fetchPostById",
  async (postId) => {
    try {
      const response = await axios.get(
        `https://localhost:7213/api/Posts/GetPostById/${postId}`
      );
      return response.data; // Return data directly from the Axios response
    } catch (error) {
      return error.response?.data?.message || error.message;
    }
  }
);

const postByIdSlice = createSlice({
  name: "post",
  initialState: {
    post: null,
    loading: false,
    error: null,
  },
  reducers: {
    clearPost: (state) => {
      state.post = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPostById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPostById.fulfilled, (state, action) => {
        state.loading = false;
        state.post = action.payload;
      })
      .addCase(fetchPostById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearPost } = postByIdSlice.actions;
export default postByIdSlice.reducer;

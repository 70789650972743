import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  CssBaseline,
  Avatar,
  LinearProgress,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { User, RotateCcw } from "lucide-react";
import Sidebar from "./Sidebar";

const satQuestions = [
  {
    question: "If 2x + y = 7 and 3x - 2y = 4, what is the value of x?",
    options: ["1", "2", "3", "4"],
    correct: "3",
    explanation:
      "You can solve this system of equations by substitution or elimination method.",
  },
  {
    question: "Which expression equals (x + 2)(x - 3)?",
    options: ["x² - x - 6", "x² + 5x - 6", "x² - x + 6", "x² + x - 6"],
    correct: "x² - x - 6",
    explanation: "Using FOIL: First, Outer, Inner, Last.",
  },
  {
    question: "If angle A = 60° and B = 45°, what is C?",
    options: ["65°", "75°", "85°", "95°"],
    correct: "75°",
    explanation: "Triangle angles sum to 180°. C = 180° - 60° - 45° = 75°",
  },
  {
    question: "What is the slope of a line perpendicular to y = 3x + 2?",
    options: ["-1/3", "3", "-3", "1/3"],
    correct: "-1/3",
    explanation: "Perpendicular lines have slopes that are negative reciprocals.",
  },
  {
    question: "Solve for x: |2x - 6| = 8",
    options: ["1 or 7", "2 or 8", "7 or -1", "8 or -2"],
    correct: "1 or 7",
    explanation: "When solving |2x - 6| = 8, we get 2x - 6 = 8 or 2x - 6 = -8",
  },
];

const OptionButton = ({ option, index, onClick, disabled, selected }) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    sx={{
      width: "100%",
      p: 2,
      mb: 1,
      backgroundColor: selected ? "#50C878" : "white",
      color: selected ? "white" : "#1f2937",
      border: "2px solid #e5e7eb",
      borderRadius: "8px",
      textAlign: "left",
      textTransform: "none",
      fontWeight: "500",
      transition: "all 0.2s ease",
      "&:hover": {
        backgroundColor: selected ? "#45b068" : "rgba(80, 200, 120, 0.05)",
        borderColor: "#50C878",
        transform: "translateY(-1px)",
      },
      "&:disabled": {
        backgroundColor: "#f3f4f6",
        borderColor: "#e5e7eb",
        color: "#9ca3af",
      },
    }}
  >
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {String.fromCharCode(65 + index)}. {option}
    </Box>
  </Button>
);

const GameInterface = () => {
  const [timer, setTimer] = useState(15);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [playerScore, setPlayerScore] = useState(0);
  const [aiScore, setAiScore] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);
  const [showCelebration, setShowCelebration] = useState(false);
  const [winner, setWinner] = useState(null);
  const [gameFinished, setGameFinished] = useState(false);

  const nextQuestion = () => {
    if (currentQuestion < satQuestions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
      setTimer(15);
      setSelectedAnswer(null);
      setShowExplanation(false);
    } else {
      endGame();
    }
  };

  useEffect(() => {
    let interval;
    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0 && isTimerActive) {
      nextQuestion();
    }
    return () => clearInterval(interval);
  }, [timer, isTimerActive, currentQuestion]);

  const handleAnswerSelect = (answer) => {
    setSelectedAnswer(answer);
    if (answer === satQuestions[currentQuestion].correct) {
      setPlayerScore((prev) => prev + 1);
    }

    setTimeout(() => {
      if (Math.random() < 0.7) {
        setAiScore((prev) => prev + 1);
      }
      setShowExplanation(true);
      setTimeout(nextQuestion, 2000);
    }, 1000);
  };

  const endGame = () => {
    setIsTimerActive(false);
    setGameFinished(true);
    const winner = playerScore > aiScore ? "player" : aiScore > playerScore ? "ai" : "tie";
    setWinner(winner);
    setShowCelebration(true);
  };

  const startNewGame = () => {
    setPlayerScore(0);
    setAiScore(0);
    setCurrentQuestion(0);
    setTimer(15);
    setSelectedAnswer(null);
    setShowExplanation(false);
    setIsTimerActive(true);
    setGameFinished(false);
    setShowCelebration(false);
  };

  const CelebrationDialog = () => (
    <Dialog
      open={showCelebration}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: "white",
          border: "1px solid #e5e7eb",
          borderRadius: "12px",
          overflow: "hidden",
        },
      }}
    >
      <DialogContent sx={{ textAlign: "center", py: 4, px: 3 }}>
        <Typography variant="h4" sx={{ color: "#1f2937", fontWeight: "600", mb: 3 }}>
          {winner === "tie" ? "It's a Tie!" : winner === "player" ? "You Win!" : "John Smith Wins!"}
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center", gap: 4, p: 3, backgroundColor: "#f9fafb", borderRadius: "8px" }}>
          <Box sx={{ textAlign: "center" }}>
            <Typography sx={{ fontWeight: "600", mb: 1, color: "#1f2937" }}>You</Typography>
            <Typography variant="h5" sx={{ color: "#50C878" }}>{playerScore}</Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography sx={{ fontWeight: "600", mb: 1, color: "#1f2937" }}>John Smith</Typography>
            <Typography variant="h5" sx={{ color: "#50C878" }}>{aiScore}</Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 4 }}>
        <Button
          onClick={startNewGame}
          startIcon={<RotateCcw size={20} />}
          sx={{
            backgroundColor: "#50C878",
            color: "white",
            padding: "10px 30px",
            fontWeight: "500",
            "&:hover": {
              backgroundColor: "#45b068",
            },
          }}
        >
          Play Again
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <CssBaseline />
      <Grid container>
        <Sidebar />
        <Grid 
          item 
          sx={{ 
            marginLeft: { xs: 0, md: '240px' },
            width: { xs: '100%', md: 'calc(100% - 240px)' }
          }}
        >
          <Box sx={{ padding: "20px" }}>
            <div style={{ padding: "20px" }}>
              <Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  SAT Math Practice
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Challenge yourself against our AI opponent, John Smith.
                </Typography>
              </Box>

              <Box sx={{ mt: 4, p: 4, bgcolor: "white", borderRadius: "12px", border: "1px solid #e5e7eb" }}>
                {/* Score Display */}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Avatar sx={{ bgcolor: "#50C878" }}><User size={24} /></Avatar>
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>You</Typography>
                      <Typography>Score: {playerScore}</Typography>
                    </Box>
                  </Box>

                  {!isTimerActive && !gameFinished && (
                    <Button
                      onClick={startNewGame}
                      sx={{
                        bgcolor: "#50C878",
                        color: "white",
                        "&:hover": { bgcolor: "#45b068" },
                      }}
                    >
                      Start Game
                    </Button>
                  )}

                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>John Smith</Typography>
                      <Typography>Score: {aiScore}</Typography>
                    </Box>
                    <Avatar sx={{ bgcolor: "#50C878" }}><User size={24} /></Avatar>
                  </Box>
                </Box>

                {/* Timer */}
                {isTimerActive && (
                  <LinearProgress
                    variant="determinate"
                    value={(timer / 15) * 100}
                    sx={{
                      mb: 3,
                      height: 8,
                      borderRadius: 4,
                      bgcolor: "#f3f4f6",
                      "& .MuiLinearProgress-bar": {
                        bgcolor: "#50C878",
                        borderRadius: 4,
                      },
                    }}
                  />
                )}

                {/* Question */}
                {isTimerActive && (
                  <>
                    <Typography variant="h6" sx={{ mb: 3, fontWeight: "600" }}>
                      {satQuestions[currentQuestion].question}
                    </Typography>

                    {/* Options */}
                    <Box sx={{ mt: 2 }}>
                      {satQuestions[currentQuestion].options.map((option, index) => (
                        <OptionButton
                          key={index}
                          option={option}
                          index={index}
                          onClick={() => handleAnswerSelect(option)}
                          selected={selectedAnswer === option}
                          disabled={showExplanation}
                        />
                      ))}
                    </Box>

                    {/* Explanation */}
                    {showExplanation && (
                      <Box sx={{ mt: 2, p: 2, bgcolor: "#f9fafb", borderRadius: "8px", border: "1px solid #e5e7eb" }}>
                        <Typography variant="body1" sx={{ fontWeight: "500" }}>
                          {satQuestions[currentQuestion].explanation}
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </div>
          </Box>

          <CelebrationDialog />
        </Grid>
      </Grid>
    </>
  );
};

export default GameInterface;
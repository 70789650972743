import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  ButtonGroup,
  Chip,
  CssBaseline,
  Modal,
  TextField,
  CircularProgress,
  Alert,
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import Tooltip from "@mui/material/Tooltip";
//import Divider from '@mui/material/Divider';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// Payment Form Component
const PaymentForm = ({ plan, pricingPeriod, onClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // API URL (ensure this is correct)
      const API_URL =
        process.env.REACT_APP_API_URL || "https://moinduel.somee.com";

      // Calculate the amount based on the selected plan and pricing period
      const amount =
        pricingPeriod === "monthly"
          ? plan.title === "Pro"
            ? 499
            : 1499
          : plan.title === "Pro"
          ? Math.round(499 * 12 * 0.8) // 20% off the yearly price (12 * 4.99 * 0.8)
          : Math.round(1499 * 12 * 0.8); // 20% off another plan yearly price (12 * 14.99 * 0.8)

      // Create the request body
      const requestBody = {
        amount, // Amount in cents
        currency: "usd", // Currency
        description: `${plan.title} Plan Subscription`, // Description of the payment
      };

      // Print the request body to verify it
      console.log("Request Body:", JSON.stringify(requestBody, null, 2)); // Pretty print the body

      // Make the API request
      const response = await fetch(
        `${API_URL}/api/Payment/create-payment-intent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      // Check for API errors
      if (!response.ok) {
        throw new Error("Failed to create payment intent.");
      }

      const { clientSecret } = await response.json();

      // Proceed with Stripe payment confirmation
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: name,
            email: email,
          },
        },
      });

      if (result.error) {
        setError(result.error.message);
      } else {
        setSuccess(true);
        // Handle successful payment (e.g., update user subscription status)
      }
    } catch (err) {
      setError(
        err.message || "An error occurred while processing your payment."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Subscribe to {plan.title} Plan
      </Typography>

      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          sx={{ mb: 2 }}
        />
        <Box sx={{ border: "1px solid #ccc", p: 2, borderRadius: 1 }}>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Payment successful! Your subscription is now active.
        </Alert>
      )}

      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={!stripe || loading}
          sx={{
            backgroundColor: "#0F172A",
            "&:hover": {
              backgroundColor: "#1a2237",
            },
          }}
        >
          {loading ? <CircularProgress size={24} /> : `Pay ${plan.price}`}
        </Button>
      </Box>
    </Box>
  );
};

const PricingPage = () => {
  const [pricingPeriod, setPricingPeriod] = useState("monthly");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const { pricePlan, remaningApiCalls } = useSelector((state) =>
    state.user.loginMethod === "google"
      ? state.user.googleUser
      : state.user.customUser
  );

  const pricingPlans = [
    {
      title: "Free",
      price: "$0",
      priceDetail: "",
      button: "Sign up",
      features: [
        "Convert handwritten equation images to LaTeX limited(5 times per day)",
        "Access to community features",
        "Join duels with basic functionalities",
        "Ideal for casual users who want to enjoy the platform",
      ],
    },
    {
      title: "Pro",
      price: pricingPeriod === "monthly" ? "$4.99" : "$47.99",
      priceDetail: "per month",
      button: "Get started",
      popular: true,
      features: [
        "Everything in Free +",
        "Unlimited posting with Latex",
        "Unlimited posts with handwriting to Latex technology",
        "Priority support",
        "Unlimited posts with handwriting to Latex technology",
        "Enhanced profile customization options(coming soon)",
        "Early access to beta features",
        "Analytics dashboard(coming soon)",
      ],
    },
    {
      title: "Institution and schools",
      price: "Lets Talk!",
      priceDetail: "",
      button: "Contact sales",
      features: [
        "Everything in Premium +",
        "Unlimited posting and problem submissions for students and staff",
        "Dedicated dashboard for class management and progress tracking",
        "Priority support and custom content integration",
        "Perfect for schools looking to make math fun and engaging for students",
      ],
    },
  ];

  const handlePlanSelect = (plan) => {
    if (plan.title === "Institution and schools") {
      // Handle contact sales differently
      window.location.href = "/contact-sales"; // Or show a different modal
      return;
    }
    if (plan.title !== "Free") {
      // Only show payment modal for paid plans
      setSelectedPlan(plan);
      setModalOpen(true);
    } else {
      // Handle free plan signup
      window.location.href = "/signup";
    }
  };

  return (
    <>
      <CssBaseline />
      <Grid container>
        <Sidebar />
        <Grid
          item
          sx={{
            marginLeft: { xs: 0, md: "240px" },
            width: {
              xs: "100%",
              md: "calc(100% - 240px)",
              xl: "calc(100% - 450px)",
            },
          }}
        >
          <Box sx={{ padding: "40px", maxWidth: "1200px", margin: "0 auto" }}>
            <Box
              sx={{
                marginBottom: 4,
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                alignItems: { xs: "stretch", sm: "flex-start" },
                gap: 2,
              }}
            >
              <Box>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Pricing Plans
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Choose a plan that fits your needs for converting images to
                  LaTeX and engaging in SAT duels.
                </Typography>
              </Box>

              <ButtonGroup
                size="small"
                sx={{
                  backgroundColor: "#F3F4F6",
                  padding: "2px",
                  borderRadius: "20px",
                  alignSelf: { xs: "center", sm: "flex-start" },
                  "& .MuiButton-root": {
                    minWidth: "100px",
                    borderRadius: "16px",
                    textTransform: "none",
                    fontSize: "14px",
                    padding: "4px 12px",
                    border: "none",
                    "&.active": {
                      backgroundColor: "#fff",
                      color: "#0F172A",
                      boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                    },
                    "&:hover": {
                      backgroundColor: "rgba(80, 200, 120, 0.1)",
                    },
                  },
                }}
              >
                <Button
                  className={pricingPeriod === "monthly" ? "active" : ""}
                  onClick={() => setPricingPeriod("monthly")}
                >
                  Pay monthly
                </Button>
                <Button
                  className={pricingPeriod === "yearly" ? "active" : ""}
                  onClick={() => setPricingPeriod("yearly")}
                >
                  Pay yearly{" "}
                  <Typography
                    component="span"
                    sx={{
                      color: "#50C878",
                      marginLeft: "4px",
                      fontSize: "12px",
                    }}
                  >
                    save 20%
                  </Typography>
                </Button>
              </ButtonGroup>
            </Box>

            <Grid container spacing={2} sx={{ maxWidth: "100%" }}>
              {pricingPlans.map((plan) => (
                <Grid item xs={12} sm={6} lg={4} key={plan.title}>
                  <Card
                    sx={{
                      height: "450px",
                      backgroundColor: "#F9FAFB",
                      position: "relative",
                      border: "1px solid #E5E7EB",
                      boxShadow: "none",
                      transition: "transform 0.2s, border-color 0.2s",
                      "&:hover": {
                        border: "1px solid #50C878",
                        transform: "translateY(-4px)",
                      },
                    }}
                  >
                    <CardContent sx={{ padding: 2, height: "100%" }}>
                      {plan.popular && (
                        <Chip
                          label="Popular"
                          color="primary"
                          size="small"
                          sx={{
                            position: "absolute",
                            top: 12,
                            right: 12,
                            backgroundColor: "#50C878",
                          }}
                        />
                      )}

                      <Box sx={{ marginBottom: 2 }}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{ fontSize: "1.1rem" }}
                        >
                          {plan.title}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "baseline",
                            gap: 0.5,
                          }}
                        >
                          <Typography
                            variant="h5"
                            component="span"
                            sx={{ fontWeight: 600, fontSize: "1.3rem" }}
                          >
                            {plan.price}
                          </Typography>
                          {plan.priceDetail && (
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ fontSize: "0.8rem" }}
                            >
                              {plan.priceDetail}
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      <Button
                        variant={
                          pricePlan === plan.title ? "contained" : "outlined"
                        }
                        fullWidth
                        size="small"
                        onClick={() => setSelectedPlan(plan)}
                        disabled={pricePlan === plan.title} // Disable if this is the active plan
                        sx={{
                          marginBottom: 1,
                          textTransform: "none",
                          ...(pricePlan === plan.title
                            ? {
                                backgroundColor: "#0F172A",
                                "&:hover": {
                                  backgroundColor: "#1a2237",
                                },
                              }
                            : {
                                color: "#0F172A",
                                borderColor: "#0F172A",
                                "&:hover": {
                                  backgroundColor: "rgba(80, 200, 120, 0.1)",
                                  borderColor: "#50C878",
                                },
                              }),
                        }}
                      >
                        {pricePlan === plan.title ? "Active Plan" : plan.button}
                      </Button>

                      {/* Display remaining API calls if this is the active plan */}
                      {pricePlan === plan.title && (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            textAlign: "center",
                            marginBottom: 2,
                            fontWeight: "600",
                          }}
                        >
                          Remaining API Calls: {remaningApiCalls}
                        </Typography>
                      )}

                      <Box
                        component="ul"
                        sx={{
                          listStyle: "none",
                          padding: 0,
                          margin: 0,
                          "& li": {
                            display: "flex",
                            alignItems: "start",
                            gap: 1,
                            marginBottom: 1,
                          },
                        }}
                      >
                        {plan.features.map((feature, index) => (
                          <Box component="li" key={index}>
                            <Typography
                              component="span"
                              sx={{ color: "#9CA3AF" }}
                            >
                              •
                            </Typography>

                            {/* Add Tooltip for specific feature */}
                            {feature === "Access to community features" ? (
                              <Tooltip
                                title={
                                  <>
                                    <Typography variant="body2">
                                      - Challenges of the Day: Math problems
                                      posted daily where users can compete for
                                      rewards.
                                    </Typography>
                                    <Typography variant="body2">
                                      - Collaborative problem-solving events.
                                    </Typography>
                                    <Typography variant="body2">
                                      - Achievement badges for activity in both
                                      forum and gaming aspects.
                                    </Typography>
                                  </>
                                }
                              >
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  sx={{ flex: 1, fontSize: "0.8rem" }}
                                >
                                  {feature}
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ flex: 1, fontSize: "0.8rem" }}
                              >
                                {feature}
                              </Typography>
                            )}
                          </Box>
                        ))}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Payment Modal */}
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="payment-modal"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "white",
                borderRadius: 2,
                maxWidth: 500,
                width: "90%",
                maxHeight: "90vh",
                overflow: "auto",
              }}
            >
              {selectedPlan && (
                <Elements stripe={stripePromise}>
                  <PaymentForm
                    plan={selectedPlan}
                    pricingPeriod={pricingPeriod}
                    onClose={() => setModalOpen(false)}
                  />
                </Elements>
              )}
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </>
  );
};

export default PricingPage;

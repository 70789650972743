// actions/commentActions.js
import axios from "axios";

export const FETCH_COMMENTS_REQUEST = "FETCH_COMMENTS_REQUEST";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_FAILURE = "FETCH_COMMENTS_FAILURE";

export const fetchComments = (postId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_COMMENTS_REQUEST });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/Posts/GetAllCommentById/${postId}`
      );
      dispatch({ type: FETCH_COMMENTS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_COMMENTS_FAILURE, payload: error.message });
    }
  };
};

// actions/userActions.js

export const loginWithGoogle = (googleUser) => ({
  type: "LOGIN_WITH_GOOGLE",
  payload: googleUser,
});

export const loginWithCustomEmail = (customUser) => ({
  type: "LOGIN_WITH_CUSTOM_EMAIL",
  payload: customUser,
});

export const logoutUser = () => ({
  type: "LOGOUT_USER",
});

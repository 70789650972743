import React from "react";
import katex from "katex";
import "katex/dist/katex.min.css";

const Latex = ({ formula, fontSize = "1rem" }) => {
  const cleanFormula = React.useMemo(() => {
    // Remove \( and \) and trim
    let processed = formula.replace(/\\\(|\\\)/g, '').trim();
    
    // Add alignment environment to handle long equations
    processed = `\\begin{aligned} ${processed} \\end{aligned}`;
    
    return processed;
  }, [formula]);

  try {
    const html = katex.renderToString(cleanFormula, {
      throwOnError: false,
      displayMode: true,
      trust: true,
      strict: false,
      maxExpand: 1000,
      maxSize: 4
    });

    return (
      <div style={{ 
        width: '100%',
        overflowX: 'auto',
        padding: '8px 0'
      }}>
        <div style={{
          maxWidth: '100%',
          margin: '0 auto'
        }}>
          <span 
            style={{ fontSize, display: "inline-block" }} 
            dangerouslySetInnerHTML={{ __html: html }} 
          />
        </div>
      </div>
    );
  } catch (error) {
    console.error("KaTeX error:", error);
    return <div className="text-red-500">Failed to render LaTeX equation</div>;
  }
};

export default Latex;
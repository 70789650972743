import React from "react";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";

const MarkdownMathRenderer = ({ markdownContent }) => {
  return (
    <div>
      <ReactMarkdown
        children={markdownContent}
        remarkPlugins={[remarkMath]} // Enable math parsing
        rehypePlugins={[rehypeKatex]} // Render LaTeX equations
      />
    </div>
  );
};

export default MarkdownMathRenderer;

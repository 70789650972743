import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import Layout from "./components/Layout";
import CreatePost from "./components/CreatePost";
import { Provider, useDispatch } from "react-redux";
import store from "./redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.css";
import { useEffect } from "react";
import { fetchPosts } from "./redux/reducers/postReducer";
import DueList from "./components/DueList";
import PricingPage from "./components/Pricing";
import IMORoadmap from "./components/IMORoadmap";
import SATQuizGame from "./components/PlayGame";
import TermsAndConditions from "./components/TermsCondition";
import PrivacyPolicy from "./components/Privacy";
import About from "./components/About";
import KangarooRoadmap from "./components/KangarooRM";
import "katex/dist/katex.min.css";
import ReadPost from "./components/ReadPost";
import Feedback from "./components/Feadback";
import PicToLatex from "./components/PictureLatex";
import ProfilePage from "./components/ProfilePage";
import Learn from "./components/Learn";
import DailyPuzzles from "./components/Puzzle";
import AiTeacherChat from "./components/Moinchat";
import CompetitionSubmission from "./components/Competition";
import { fetchUsers } from "./redux/reducers/usersSlice";
import Header from "./components/Header";
import { GoogleOAuthProvider } from "@react-oauth/google";

function Root() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthPage =
    location.pathname === "/login" || location.pathname === "/signup";

  useEffect(() => {
    dispatch(fetchPosts());
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <>
      {!isAuthPage && <Header />}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/Play" element={<SATQuizGame />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/create-post" element={<CreatePost />} />
        <Route path="/duelist" element={<DueList />} />
        <Route path="/imoroadmap" element={<IMORoadmap />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/post/:postId" element={<ReadPost />} />
        <Route path="/edit/:postId" element={<CreatePost />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/termscondition" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/about" element={<About />} />
        <Route path="/kangaroo" element={<KangarooRoadmap />} />
        <Route path="/pic-to-latex" element={<PicToLatex />} />
        <Route path="/learn" element={<Learn />} />
        <Route path="/puzzles" element={<DailyPuzzles />} />
        <Route path="/aiteacherchat" element={<AiTeacherChat />} />
        <Route path="/competition" element={<CompetitionSubmission />} />        
        <Route path="/feedback" element={<Feedback />} />
      </Routes>
    </>
  );
}

const App = () => (
  <Provider store={store}>
    <GoogleOAuthProvider clientId="982148252908-sig7bg6uetu7trijs3kuof828t3ki30p.apps.googleusercontent.com">
      <Router>
        <Root />
      </Router>
    </GoogleOAuthProvider>
  </Provider>
);

export default App;

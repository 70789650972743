// reducers/userReducer.js

const initialState = {
  isLoggedIn: false,
  loginMethod: null,
  googleUser: {
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    role: [],
    pricePlan: "",
    perDayLimit: null,
    remaningApiCalls: null,
  },
  customUser: {
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    role: [],
    pricePlan: "",
    perDayLimit: null,
    remaningApiCalls: null,
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_WITH_GOOGLE":
      return {
        ...state,
        isLoggedIn: true,
        loginMethod: "google",
        googleUser: action.payload,
      };
    case "LOGIN_WITH_CUSTOM_EMAIL":
      return {
        ...state,
        isLoggedIn: true,
        loginMethod: "custom",
        customUser: action.payload,
      };
    case "LOGOUT_USER":
      return {
        ...state,
        isLoggedIn: false,
        loginMethod: null,
        googleUser: {
          userId: "",
          firstName: "",
          lastName: "",
          email: "",
          pricePlan: "",
          perDayLimit: null,
          remaningApiCalls: null,
        },
        customUser: {
          userId: "",
          firstName: "",
          lastName: "",
          email: "",
          pricePlan: "",
          perDayLimit: null,
          remaningApiCalls: null,
        },
      };
    default:
      return state;
  }
};

export default userReducer; // Change to default export

import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Link,
  Grid2,
  Paper,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Logo from "../images/Logo For Website 02.png";
import Logo1 from "../images/Logo For Website 01.png";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  loginWithCustomEmail,
  loginWithGoogle,
} from "../redux/actions/loginActions";
import { toast } from "react-toastify";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [isGoogleSignUp, setIsGoogleSignUp] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleSignUp = () => {
    navigate("/signup");
  };

  const handleScreenClick = () => {
    navigate("/");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const loadingToastId = toast.loading("Logging in...");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/Auth/login`,
        formData
      );
      const token = response.data.token;

      const authData = {
        token: token,
        data: "",
        loginMethod: "custom",
      };

      localStorage.setItem("serviceToken", JSON.stringify(authData));

      if (token) {
        const userDate = jwtDecode(token);
        console.log("current user data : ", userDate);
        const customLogin = {
          userId: userDate.userId,
          firstName: userDate.firstName,
          lastName: userDate.lastName,
          email: userDate.email,
          role: userDate.role,
          pricePlan: userDate.pricePlan,
          perDayLimit: userDate.perDayLimit,
          remaningApiCalls: userDate.remaningApiCalls,
        };
        dispatch(loginWithCustomEmail(customLogin));
        toast.update(loadingToastId, {
          render: "Logged in successfully!",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
        });
        navigate("/");
      }
    } catch (error) {
      toast.update(loadingToastId, {
        render: "Invalid email or password.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid2 container style={{ minHeight: "100vh" }}>
      {/* Left side - Login Form */}
      <Grid2
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f5f5f5",
          width: { xs: "100%", md: "50%" },
          minHeight: { xs: "100vh", md: "auto" },
          padding: { xs: "20px", md: 0 },
        }}
      >
        <Paper
          elevation={0}
          sx={{
            padding: "20px",
            borderRadius: "8px",
            width: { xs: "100%", sm: "90%", md: "50%" },
            maxWidth: { xs: "100%", sm: "450px" },
            backgroundColor: "#f5f5f5",
            margin: { xs: "0", md: "auto" },
          }}
        >
          <Box textAlign="center" mb={2}>
            <img
              src={Logo1}
              alt="Logo"
              style={{ width: "200px", maxWidth: "100%" }}
            />
          </Box>
          <Typography variant="h5" align="center" gutterBottom>
            Sign In
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "15px 12px",
                },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "15px 12px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box textAlign="right">
              <Link href="#" variant="body2">
                Forgot Password?
              </Link>
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{
                marginTop: "20px",
                backgroundColor: "#0F172A",
                textTransform: "none",
              }}
            >
              Sign In
            </Button>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              my={2}
            >
              <hr
                style={{
                  flexGrow: 1,
                  border: "1px solid #ccc",
                  marginRight: "10px",
                }}
              />
              <Typography variant="body2" style={{ margin: "0 10px" }}>
                OR
              </Typography>
              <hr
                style={{
                  flexGrow: 1,
                  border: "1px solid #ccc",
                  marginLeft: "10px",
                }}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              my={2}
            >
              <GoogleLogin
                onSuccess={async (response) => {
                  const decodedUser = jwtDecode(response.credential);
                  const loadingToastId = toast.loading(
                    "Logging in with Google..."
                  );

                  setIsGoogleSignUp(true);

                  try {
                    const googleUser = {
                      firstName: decodedUser.given_name,
                      lastName: decodedUser.family_name,
                      email: decodedUser.email,
                      password: "",
                      pricePlan: "Free",
                      perDayLimit: 5,
                    };

                    const res = await axios.post(
                      `${process.env.REACT_APP_API_URL}/api/Auth/signup`,
                      { ...googleUser, isGoogleSignUp: true }
                    );

                    console.log(res.data);

                    const authData = {
                      token: response.credential,
                      data: res.data,
                      loginMethod: "google",
                    };

                    localStorage.setItem(
                      "serviceToken",
                      JSON.stringify(authData)
                    );

                    if (res.status === 200) {
                      dispatch(loginWithGoogle(res.data));
                    } else {
                      dispatch(loginWithGoogle(res.data));
                    }
                    toast.update(loadingToastId, {
                      render: "Logged in successfully with Google!",
                      type: "success",
                      isLoading: false,
                      autoClose: 5000,
                      closeOnClick: true,
                    });
                    navigate("/");
                  } catch (error) {
                    toast.update(loadingToastId, {
                      render: "Login failed. Please try again.",
                      type: "error",
                      isLoading: false,
                      autoClose: 5000,
                      closeOnClick: true,
                    });
                    console.error("Google login failed:", error);
                  }
                }}
                onError={() => console.log("Login failed")}
              />
            </Box>
            <Box textAlign="center" mt={2}>
              <Typography variant="body2">
                Don't have an account?{" "}
                <Link
                  href="#"
                  variant="body2"
                  color="primary"
                  onClick={handleSignUp}
                >
                  Sign Up
                </Link>
              </Typography>
            </Box>
            <Box textAlign="center" mt={2}>
              <Typography variant="body2">
                By clicking continue, you agree to our{" "}
                <Link
                  href="#"
                  variant="body2"
                  color="primary"
                  onClick={handleSignUp}
                >
                  Privacy Policy
                </Link>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid2>

      {/* Right side - Black section with logo */}
      <Grid2
        item
        md={6}
        sx={{
          display: { xs: "none", md: "flex" },
          cursor: "pointer",
          backgroundColor: "#000000",
          justifyContent: "center",
          alignItems: "center",
          width: "50%",
        }}
        onClick={handleScreenClick}
      >
        <Box textAlign="center">
          <img
            src={Logo}
            alt="Logo"
            style={{ width: "300px", maxWidth: "100%" }}
          />
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  CssBaseline,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Sidebar from "./Sidebar";
import axios from "axios";
import { fetchPosts } from "../redux/reducers/postReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Latex from "./Latex";
import MarkdownMathRenderer from "./MarkdownMathRenderer";
import { fetchPostById } from "../redux/reducers/postByIdSlice";

const CreatePost = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postId } = useParams();
  const user = useSelector((state) => state.user);
  // const isLoggedIn = user.isLoggedIn;
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [latexImage, setLatexImage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const [latexResult, setLatexResult] = useState("");
  const [markDownResult, setMarkDownResult] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [userId, setUserId] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (user.loginMethod === "google") {
      setUserId(user.googleUser.userId);
    } else if (user.loginMethod === "custom") {
      setUserId(user.customUser.userId);
    } else {
      setUserId(""); // Reset userId if no login method is found
    }

    if (postId) {
      getPostById(postId);
    }
  }, [
    user.customUser.userId,
    user.loginMethod,
    user.googleUser.userId,
    postId,
  ]);

  const getPostById = async (postId) => {
    const actionResult = await dispatch(fetchPostById(postId));

    // Check if the request was fulfilled
    if (fetchPostById.fulfilled.match(actionResult)) {
      console.log(actionResult.payload);
      setTitle(actionResult.payload.title);
      setContent(actionResult.payload.content);
      setLatexResult(actionResult.payload.latex);
      setMarkDownResult(actionResult.payload.latex_Text);
    } else {
      // Handle error if needed
      console.error("Failed to fetch post:", actionResult.payload);
    }
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      SubmitThubmnailImage(file); // Call the API to get LaTeX
    }
  };

  const SubmitThubmnailImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    handleSubmitThumbnail(formData);
  };

  const handleLatexFromImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLatexImage(file);
      // fetchLatexFromImage(file); // Call the API to get LaTeX
    }
  };

  const handleProcess = () => {
    setIsProcessing(true);
    fetchLatexFromImage(latexImage);
  };

  const fetchLatexFromImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        "https://api.mathpix.com/v3/text",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            app_id: "moinduel_391939_069b97",
            app_key:
              "f93620f23d66894460642dc8bf4e7cd9011208557744209c13d433a709c47186",
          },
        }
      );

      // Set result (response)
      console.log(response.data);
      let extractedText = response.data.latex_styled || response.data.text;

      if (extractedText === response.data.text) {
        extractedText = replaceMathDelimiters(extractedText);
        setMarkDownResult(extractedText);
      } else {
        setLatexResult(extractedText);
      }
    } catch (error) {
      console.error("Error extracting text:", error);
      toast.error("Failed to extract LaTeX from the image.", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const replaceMathDelimiters = (text) => {
    return (
      text
        // Replace \( and \) with $
        .replace(/\\\(/g, "$")
        .replace(/\\\)/g, "$")
        // Replace \[ and \] with $$
        .replace(/\\\[/g, "$$")
        .replace(/\\\]/g, "$$")
    );
  };

  const handleThumbnailClick = () => {
    document.getElementById("file-input").click();
  };
  const handleLatexClick = () => {
    document.getElementById("file-input-latex").click();
  };
  const handlePublish = async () => {
    if (title.trim() === "") {
      setError(true);
      return;
    } else {
      setError(false);
    }

    if (content.trim() === "" && !markDownResult) {
      alert("content or math equation required.");
      return;
    }

    const postData = {
      title: title,
      content: content,
      latex: latexResult,
      latex_Text: markDownResult,
      imagePath: thumbnail.path,
    };

    console.log("update date : ", postData);
    const createPostUrl = `${process.env.REACT_APP_API_URL}/api/Posts/create`;

    if (postId) {
      try {
        const response = await axios.put(
          `https://localhost:7213/api/Posts/update/${postId}`,
          postData
        );

        if (response.status === 200) {
          setTitle("");
          setContent("");
          setSelectedImage(null);
          setLatexResult("");
          setMarkDownResult("");
          setLatexImage(null);
          toast.success("Post updated successfully!.", {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          navigate(-1);
        } else {
          toast.error("Something is wrong, try again.", {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        }
      } catch (error) {
        console.error("Error updating post:", error);
        toast.error("Something is wrong, try again.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } else {
      try {
        // Make the API request to create a post
        const response = await axios.post(createPostUrl, postData, {
          params: { userId }, // Passing userId as a URL parameter
        });

        if (response.status === 200) {
          // Successfully created post
          dispatch(fetchPosts());

          setTitle("");
          setContent("");
          setSelectedImage(null);
          setLatexResult("");
          setMarkDownResult("");
          setLatexImage(null);

          toast.success("Post created successfully!.", {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });

          navigate("/");
        }
      } catch (error) {
        console.error("Failed to create post", error);
        toast.error("Something is wrong, try again.", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };

  // Handle form submission and file upload
  const handleSubmitThumbnail = async (formData) => {
    try {
      // Send a POST request to the server to upload the file
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/FileUpload/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the successful response
      console.log("Image uploaded successfully!", response.data);
      setThumbnail(response.data); // Assuming the API returns fileUrl
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <>
      <CssBaseline />
      <Grid container sx={{ justifyContent: "center" }}>
        <Sidebar />
        <Grid
          item
          sx={{
            marginLeft: { xs: 0, md: "240px" }, // Adjust margin based on sidebar width
            width: { xs: "100%", md: "calc(100% - 240px)" }, // Adjust width to account for sidebar
          }}
        >
          <Box sx={{ padding: "20px", width: "80%", margin: "auto" }}>
            <Grid container alignItems="center">
              <Grid item xs>
                <TextField
                  placeholder="Title"
                  variant="standard"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  fullWidth
                  error={error}
                  helperText={error ? "This field is required." : ""}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: "1.875rem",
                      opacity: 0.5,
                      lineHeight: "2.25rem",
                    },
                  }}
                  slotProps={{
                    input: {
                      disableUnderline: true,
                      style: {
                        fontSize: "1.875rem",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ backgroundColor: "#0F172A", padding: "8px 16px" }}
                  onClick={handlePublish}
                >
                  {postId ? "Update" : "Publish"}
                </Button>
              </Grid>
            </Grid>
            <Box sx={{ marginTop: "20px" }}>
              {/* Hidden input for file selection */}

              {/* Thumbnail Photo Placeholder */}
              <Grid
                container
                spacing={2} // Adds space between the boxes
                sx={{
                  flexDirection: { xs: "column", sm: "row" },
                }} // Stacks vertically on small screens and in a row on larger screens
              >
                <Grid item xs={12} sm={6}>
                  <input
                    type="file"
                    id="file-input"
                    accept=".jpg,.jpeg,.png" // Only accepts .jpg and .png files
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  <Box
                    onClick={handleThumbnailClick}
                    sx={{
                      width: "50%", // Fill the grid item
                      height: "150px",
                      border: "2px dashed #d3d3d3",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      marginBottom: "20px",
                      backgroundImage: selectedImage
                        ? `url(${selectedImage})`
                        : "none",
                      backgroundColor: selectedImage
                        ? "transparent"
                        : "#f5f5f5", // Light gray background when no image is uploaded
                      backgroundSize: "contain", // Ensure the image fits within the box
                      backgroundRepeat: "no-repeat", // Prevent the image from repeating
                      backgroundPosition: "center",
                    }}
                  >
                    {!selectedImage && (
                      <>
                        <AddPhotoAlternateIcon
                          sx={{ fontSize: 40, color: "#a1a1a1" }}
                        />
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 500, color: "#4a4a4a" }}
                        >
                          Upload Thumbnail Image
                        </Typography>
                      </>
                    )}
                  </Box>
                </Grid>

                <input
                  type="file"
                  id="file-input-latex"
                  accept=".jpg,.jpeg,.png" // Only accepts .jpg and .png files
                  style={{ display: "none" }}
                  onChange={handleLatexFromImage}
                />
                <Grid item xs={12} sm={6}>
                  <Box
                    onClick={handleLatexClick}
                    sx={{
                      position: "relative", // Make the box relative for absolute positioning of the button
                      width: "50%", // Fill the grid item
                      height: "150px",
                      border: "2px dashed #d3d3d3",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      marginBottom: "20px",
                      backgroundImage: latexImage
                        ? `url(${latexImage})`
                        : "none",
                      backgroundColor: latexImage ? "transparent" : "#f5f5f5", // Light gray background when no image is uploaded
                      backgroundSize: "contain", // Ensure the image fits within the box
                      backgroundRepeat: "no-repeat", // Prevent the image from repeating
                      backgroundPosition: "center",
                    }}
                  >
                    {!latexImage && (
                      <>
                        <AddPhotoAlternateIcon
                          sx={{ fontSize: 40, color: "#a1a1a1" }}
                        />
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 500, color: "#4a4a4a" }}
                        >
                          Upload Image to get Latex
                        </Typography>
                      </>
                    )}
                    {latexImage && (
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          position: "absolute",
                          top: "50%",
                          right: "-125px",
                          transform: "translateY(-50%)",
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleProcess();
                        }}
                      >
                        Process
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
              {!isProcessing && (
                <Box sx={{ marginTop: "20px", textAlign: "center" }}>
                  <Latex formula={latexResult} />
                  <MarkdownMathRenderer markdownContent={markDownResult} />
                </Box>
              )}
              <TextField
                multiline
                rows={15}
                placeholder="Start writing your story here..."
                variant="standard"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                fullWidth
                error={error} // Show error state
                helperText={error ? "This field is required." : ""}
                sx={{
                  borderRadius: "4px",
                  height: "350px",
                }}
                slotProps={{
                  input: {
                    disableUnderline: true,
                  },
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CreatePost;
